
.kontakt{
    margin:0 auto;
    width:80vw;
}
/* Style inputs with type="text", select elements and textareas */
input[type=text], input[type=email],select, textarea {
    width: 100%; 
    padding: 12px; 
    border: 1px solid #ccc; 
    border-radius: 4px; 
    box-sizing: border-box; 
    margin-top: 6px; 
    margin-bottom: 16px; 
    resize: vertical 
  }
  textarea{
    height:15vh;
  }
  /* Style the submit button with a specific background color etc */
  input[type=submit] {
    background-color: #4682b4;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* When moving the mouse over the submit button, add a darker green color */
  input[type=submit]:hover {
    background-color: #4682b4;
  }
  
h1{
    color:#4682b4;
}
/* DESKTOP DESIGN STARTS HERE */
@media only screen and (min-width: 1001px) {
    .kontakt{
        margin:0 auto;
        width:50vw;
        font-size:17px;     
    }
    input[type=text],input[type=email], select {
        width: 100%; 
        padding: 12px; 
        border: 1px solid #ccc; 
        border-radius: 4px; 
        box-sizing: border-box; 
        margin-top: 6px; 
        margin-bottom: 16px; 
        resize: vertical 
      }
      textarea{
        width: 100%; 
        height:25vh;
        padding: 12px; 
        border: 1px solid #ccc; 
        border-radius: 4px; 
        box-sizing: border-box; 
        margin-top: 6px; 
        margin-bottom: 16px; 
        resize: vertical 
      }
}