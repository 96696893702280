.row_2, .row{
    display:none;
}
.mobile-nav-info{
    transform: translateX(-250px);
    
}
.mobile-nav-infovisible{
    padding:5px;
    height:20px;
    color:#fff;
    background-color: #4682b4;
    transform: translateX(0px);
    transition: transform ease-in-out 0.8s; 
}
.mobile-nav > img{
    height:65px;    
}
/* HAMBURGER MENÜ STARTS HERE */
.mobile-nav {
    padding:20px;
    overflow: hidden;
    background-color: #fff;
    position: relative;
  }
  
  
  /* Style navigation menu links */
  .mobile-nav a {
    color: #4682b4;
    padding: 20px;
    text-decoration: none;
    font-size: 19px;
    display: block;
  }
  .mobile-nav li {
    list-style: none;
  }
  .mobile-nav ul {
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Style the hamburger menu */
.ham_icon {
    border-style: none;
    border-radius: 15%;
    color:#4682b4;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    margin:25px;
  }

  .MobileNavLinks{  
    position: absolute;
    background: #fff;
    max-width: 290px;
    transform: translateX(-100px);
}
.MobileNavLinksshow_menu{
    transform: translateX(0px);
    transition: transform ease-in-out 0.5s;
    font-size: 18px;
    overflow: hidden;
    z-index: 9;
}



/* HAMBURGER MENÜ ENDS HERE */


/* DESKTOP DESIGN STARTS HERE */
@media only screen and (min-width: 1001px) {
.mobile-nav,.mobile-nav-info{
    display:none;
}
 
*{
    box-sizing: border-box;
}
#top{
    justify-content: center;
    position: relative;
}
/* navbar design */
.container{
    padding-bottom:15px;
    position: relative;
    width:100%;
}
.row{
    display:flex !important;
    justify-content: space-around;
    align-items: center;

}

/* IMG design */
.left_side_nav{
    margin-left:10%;
    padding:20px;
}
.left_side_nav > img{
    height:100px;
    
}

/* right nav design */
.header-kontakt-icon{
    right:0;
    width:30%;
    line-height: 1.3 !important;
    color:#4682b4;
    align-items: center;
    display:flex;
    flex-direction: column;
}
.direkt-kontakt{
    font-size:12px;
    background: #4682b4 !important;
    color:#fff !important;
    text-decoration: none;
    padding:6px;
    line-height: 20px;
    text-transform: uppercase;
    font-family: sans-serif;
    font-weight: 700;
    border-radius: 3px;
    letter-spacing: 2px;
}

/* nav links design */
.row_2{
    display:block;
    float: none!important;
    width: 100%!important;

}
nav{
    display:flex;
    justify-content: center;
    width: 100%;
}
.link{
    padding:10px;
}
.link a{
    color: #888;
    text-decoration: none;
    font-size: 12px;
    font-family: sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    padding:0 10px;
}
.link a:hover{
    text-decoration: underline;
    color:#4682b4;
}
.link a:active{
    text-decoration: underline;
    color:#4682b4;
    }
}
