.card_desktop,.card_desktopvisible,.beschreibung_desktop{
    display:none;
}
.pflege_container{
    margin-top: 5vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    padding:5px;
    text-align: left;

}
.beschreibung_mobile{
    width:80vw;
    padding:10px;
    font-size:15px;
    
}
.beschreibung_mobile h1{
    color: #4682b4;
}
.card_mobile{
    border-radius:10px;
    display: grid;
    grid-template-rows: max-content 200px 1fr;
    padding:25px;
    margin:5px;
    width:80vw;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    overflow: hidden;
}


.card_mobile img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.card_mobile h4{
    color: #4682b4;
}
.info_btn{
    background-color: #4682b4;
    color:#fff;
    border-radius: 5px;
    border:none;
    padding:5px;

}
.modal_mobile{
    display:none;
    position:fixed;
    z-index:1;
    left:0;
    top:0;
    width:100%;
    height:100%;
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4);
}
/* Show first modal */
.modal_mobileshow{
    display:block;
    position:fixed;
    z-index:1;
    left:0;
    top:0;
    width:100%;
    height:100%;
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4);
}
/* Show second modal */
.modal_mobileshow_two{
    display:block;
    position:fixed;
    z-index:1;
    left:0;
    top:0;
    width:100%;
    height:100%;
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4);
}

/* Show third modal */
.modal_mobileshow_three{
    display:block;
    position:fixed;
    z-index:1;
    left:0;
    top:0;
    width:100%;
    height:100%;
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4);
}
.modal_mobile_content{
    background-color: #fefefe;
    margin: 15% auto; 
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}
.modal_mobile_content img{
    padding-top:15px;
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }





/* DESKTOP DESIGN STARTS HERE */
@media only screen and (min-width: 1001px) {
    .card_mobile,.beschreibung_mobile{
        display:none;
    }
    .pflege_content{
      margin-top:15vh;
    }
    .pflege_container{
        display:flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height:80vh;
        padding:5px;
        text-align: left;
        justify-content: center;
    }

    .card_desktop{
        border-radius:10px;
        overflow: hidden;
        display: grid;
        grid-template-rows: max-content 350px 1fr;
        padding:25px;
        margin:15px;
        width:20vw;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        
    }
   
    .card_desktop:nth-last-of-type(3){
        opacity: 0;
    
    }
    .card_desktop:nth-last-of-type(2){
        opacity: 0;
    
    }
    .card_desktop:nth-last-of-type(1){
        opacity: 0;
    
    }
    /* if element is intersected, distribute divs */
    .card_desktopvisible{
        border-radius:10px;
        overflow: hidden;
        display: grid;
        grid-template-rows: max-content 350px 1fr;
        padding:25px;
        margin:15px;
        width:20vw;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    
        
    }
    .card_desktopvisible img {
        transform-origin: center;
        transition: transform 0.8s ease-in-out;
        object-fit: cover;
        width: 100%;
        height: 100%;

    }
    .card_desktopvisible:nth-last-of-type(3){
        transition-timing-function: ease-in;
        transition: 1.2s; 
        opacity: 1;
    }
    .card_desktopvisible:nth-last-of-type(2){
        transition-timing-function: ease-in;
        transition: 1.6s; 
        opacity: 1;
    }
    .card_desktopvisible:nth-last-of-type(1){
        transition-timing-function: ease-in;
        transition: 2s; 
        opacity: 1;
    }
    .card_desktopvisible:hover ,
    .card_desktopvisible:focus {
        transform: scale(1.1);
    }
    .card_desktopvisible h4,h1{
        color: #4682b4;
    }
    .beschreibung_desktop{
        display:block;
        margin:0 auto;
        width:60vw;
        font-size:17px;
        line-height: 25px;
        letter-spacing: 1px;
    }
}